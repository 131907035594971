<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-info ml7" icon="plus" @click="onAddRecord">Añadir</a-button>
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
			</div>
			<div class="col-md-12 mt10">
				<a-input allowClear v-model="searchText" @change="onSearch" placeholder="Ingresa parámetros de búsqueda" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="searchText == '' ? suppliersList : suppliersListFiltered" rowKey="id">
					<div slot="tradename" slot-scope="record">
						{{ record.tradename.toUpperCase() }}
					</div>
					<span slot="action" slot-scope="record">
						<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
						<a-button class="btn btn-small btn-danger ml7" size="small" icon="delete" @click="onDeleteRecord(record.id)" />
					</span>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'Suppliers',
	data() {
		return {
			searchText: '',
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Proveedor',
					scopedSlots: { customRender: 'tradename' },
				},
				{
					title: 'Email',
					dataIndex: 'email',
				},
				{
					title: 'Teléfono',
					dataIndex: 'phone_number',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			suppliersListFiltered: [],
		}
	},
	computed: {
		...mapGetters('suppliers', ['suppliersList', 'spinnerLoaderLabel', 'spinnerLoader']),
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$store.dispatch('suppliers/GET')
		},
		onAddRecord() {
			this.$store.dispatch('suppliers/NEW')
		},
		onEditRecord(id) {
			this.$store.dispatch('suppliers/GET_ONE', id)
		},
		onLinkPress(string) {
			if (utilities.isValidURL(string)) {
				window.open(string)
			} else {
				Swal.fire({
					title: 'Proveedores',
					html: 'El link parece no tener una estructura correcta',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onSearch(event) {
			let { value } = event.target
			this.suppliersListFiltered = []
			if (value != '') {
				value = value.toLowerCase()
				this.suppliersListFiltered = this.suppliersList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value)) {
							return true
						}
					}),
				)
			}
		},
		onDeleteRecord(id) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('suppliers/DELETE', {
						id,
					})
				}
			})
		},
	},
	watch: {
		suppliersList: {
			deep: true,
			handler(newData) {
				this.searchText = ''
			},
		},
	},
}
</script>